import { AppDispatch, RootState } from './../index';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { authActions } from '../reducers/AuthSlice';
import { calculationsActions } from '../reducers/CalculationsSlice';
import { EDCCalculationsActions } from '../reducers/EDCCalculationsSlice';
import { emittersEDCActions } from '../reducers/EmittersEDCSlice';
import { projectDataActions } from '../reducers/ProjectDataSlice';
import { projectsActions } from '../reducers/ProjectsSlice';
import { unitsActions } from '../reducers/UnitsSlice';
import { emittersActions } from '../reducers/EmittersSlice';
import { lateralsActions } from '../reducers/LateralsSlice';
import { mainlinesActions } from '../reducers/MainlinesSlice';
import { manifoldsActions } from '../reducers/ManifoldsSlice';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const allActions = {
  ...emittersActions,
  ...lateralsActions,
  ...mainlinesActions,
  ...manifoldsActions,
  ...authActions,
  ...calculationsActions,
  ...EDCCalculationsActions,
  ...emittersEDCActions,
  ...projectDataActions,
  ...projectsActions,
  ...unitsActions,
};

export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(allActions, dispatch);
};
