import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { btnCalculate_Click } from '../../../calculation/HydraulicCalculator';
import { useAppSelector } from '../../../store/hooks/redux';
import { CatalogItem } from '../../../store/models/catalogs/CatalogsResponse';
import { HydraulicCalculatorForm } from '../types';

export const useHydraulicCalculator = () => {
  const { mainlines } = useAppSelector((state) => state.mainlinesReducer);
  const { projectData } = useAppSelector((state) => state.projectDataReducer);
  const { units } = useAppSelector((state) => state.unitsReducer);
  const { control, handleSubmit, reset, setValue, watch } = useForm<HydraulicCalculatorForm>();

  const calculationType = watch('calculationType');
  const description = watch('description');
  const classType = watch('classType');
  const diameter = watch('diameter');
  const currentMainline = mainlines.find((item) => item.CATLOG === description);

  const lateralClassTypeFilter = (item: CatalogItem) => {
    if (projectData.Region === 'USA' && item.AltClass !== null) {
      return classType ? item.AltClass?.toString().trim() === classType : true;
    }

    return classType ? item.Class.trim() === classType : true;
  };

  const lateralDiameterFilter = (item: CatalogItem) => {
    if (projectData.Region === 'USA' && item.AltInlet !== null) {
      return diameter ? item.AltInlet === +diameter : true;
    }
    return diameter ? item.INLET === +diameter : true;
  };

  const classTypes = ['', ...new Set(mainlines.map((item) => item.Class.toString().trim()))];

  const diameters = [
    '',
    ...new Set(
      mainlines
        .filter((item) => lateralClassTypeFilter(item))
        .map((item) =>
          projectData.Region === 'USA' && item.AltInlet !== null ? item.AltInlet ?? '' : item.INLET ?? ''
        )
    ),
  ];

  const mainlinesFiltered = mainlines.filter((item) => lateralClassTypeFilter(item) && lateralDiameterFilter(item));

  const isClassTypeChanged = mainlinesFiltered.some((item) => {
    if (projectData.Region === 'USA') {
      return classType === '' || item.AltClass?.toString().trim() === classType;
    }
    return classType === '' || item.Class.trim() === classType;
  });

  const isLateralDiameterChanged = description === '' || mainlinesFiltered.some((item) => item.CATLOG === description);

  useEffect(() => {
    if (currentMainline) {
      setValue('internalDiameterValue', currentMainline.INTRNL);
    }
  }, [currentMainline]);

  useEffect(() => {
    setValue('diameter', '');
  }, [setValue, classType]);

  useEffect(() => {
    if (mainlines.length && mainlinesFiltered.length) {
      setValue('description', mainlinesFiltered[0].CATLOG);
    }
  }, [classType, diameter, mainlines, setValue]);

  useEffect(() => {
    if (Object.keys(projectData).length && Object.keys(mainlines).length) {
      reset({
        calculationType: 'headloss',
        headlossValue: 0,
        numberOfOutlets: 0,
        additionalHeadloss: 0,
        KD: 0,
        distanceBetweenOutlets: 0,
        outletFlow: 0,
        internalDiameterValue: 0,
        totalFlowValue: 20,
        velocity: 0,
        lengthValue: 100,
        classType: '',
        diameter: '',
        description: '',
        headlossCalculation: 'H.W',
        hwConstant: projectData.ManifoldHWCoef,
      });
    }
  }, [projectData, mainlines, reset]);

  const onSubmit: SubmitHandler<HydraulicCalculatorForm> = (data) => {
    const result = btnCalculate_Click(
      {
        NumberOfOutlets: +data.numberOfOutlets,
        OutletDistance: +data.distanceBetweenOutlets,
        OutletFlow: +data.outletFlow,
        Q: +data.totalFlowValue,
        Dia: +data.internalDiameterValue,
        HW: +data.hwConstant,
        Len: +data.lengthValue,
        HL: +data.headlossValue,
        kd: +data.KD,
        velocity: +data.velocity,
        addlHeadloss: +data.additionalHeadloss,
        headlossCalc: data.headlossCalculation,
        calcType: data.calculationType,
      },
      units
    );

    if (result.error) {
      toast.error(t(result.error));
      return;
    }

    result.AHL && setValue('additionalHeadloss', isFinite(result.AHL) ? result.AHL : 0);
    result.HL && setValue('headlossValue', isFinite(result.HL) ? result.HL : 0);
    result.Q && setValue('totalFlowValue', isFinite(result.Q) ? result.Q : 0);
    result.kd && setValue('KD', isFinite(result.kd) ? result.kd : 0);
    result.velocity && setValue('velocity', isFinite(result.velocity) ? result.velocity : 0);
    result.Len && setValue('lengthValue', isFinite(result.Len) ? result.Len : 0);
    result.Dia && setValue('internalDiameterValue', isFinite(result.Dia) ? result.Dia : 0);
  };

  return {
    diameters,
    control,
    handleSubmit,
    onSubmit,
    calculationType,
    classTypes,
    isLateralDiameterChanged,
    isClassTypeChanged,
    units,
    mainlinesFiltered,
  };
};
