import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Unit } from '../models/units/UnitsResponse';
import { UnitsResponse } from './../models/units/UnitsResponse';
import { getUnits, saveUnits } from './../thunks/UnitsThunks';

interface UnitsState {
  units: Unit;
  isLoading: boolean;
  unitsLength: number;
}

const initialState: UnitsState = {
  units: {} as Unit,
  isLoading: false,
  unitsLength: 0,
};

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setUnits(state, action: PayloadAction<Partial<Unit>>) {
      state.units = {...state.units, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnits.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnits.fulfilled.type, (state, action: PayloadAction<UnitsResponse>) => {
        state.isLoading = false;
        state.units = action.payload.GetUnitsResult.RootResults[0];
        state.unitsLength = action.payload.GetUnitsResult.RootResults.length;
      })
      .addCase(getUnits.rejected.type, (state) => {
        state.isLoading = false;
      })
      .addCase(saveUnits.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(saveUnits.fulfilled.type, (state) => {
        state.isLoading = false;
      })
      .addCase(saveUnits.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const unitsActions = unitsSlice.actions;

export const unitsReducer = unitsSlice.reducer;
