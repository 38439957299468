import { CatalogsByFilterResponse } from '../models/catalogs/CatalogsByFilterResponse';
import { getLaterals } from './../thunks/CatalogsThunks';
import { CatalogItem } from '../models/catalogs/CatalogsResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface lateralsState {
  laterals: CatalogItem[];
  isLoading: boolean;
}

const initialState: lateralsState = {
  laterals: [],
  isLoading: false,
};

export const lateralsSlice = createSlice({
  name: 'laterals',
  initialState,
  reducers: {
    setLaterals(state, action: PayloadAction<CatalogItem[]>) {
      state.laterals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLaterals.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getLaterals.fulfilled.type,
        (state, action: PayloadAction<CatalogsByFilterResponse>) => {
          state.isLoading = false;
          state.laterals = action.payload.GetCatalogPerDiaClassFlowSpacingResult.RootResults;
        },
      )
      .addCase(getLaterals.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const lateralsActions = lateralsSlice.actions;

export const lateralsReducer = lateralsSlice.reducer;
