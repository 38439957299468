import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from '../../api';
import { EmittersEDC } from '../models/emittersEDC/EmittersEDC';
import { EmittersEDCResult } from '../models/emittersEDC/EmittersEDCResult';
import { EmittersFamiliesResult } from '../models/emittersEDC/EmittersFamiliesResult';

export const getEmittersFamilies = createAsyncThunk(
  'catalogs/getEmittersFamilies',
  async (_, thunkAPI) => {
    try {
      const response = await customAxios.post<EmittersFamiliesResult>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetEmitterNames',
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getEmittersEDC = createAsyncThunk(
  'catalogs/getEmittersEDC',
  async (params: EmittersEDC, thunkAPI) => {
    try {
      const response = await customAxios.get<EmittersEDCResult>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetEmittersForEname',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
