import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmitterEDC, EmittersEDCResult } from '../models/emittersEDC/EmittersEDCResult';
import { EmittersFamiliesResult } from '../models/emittersEDC/EmittersFamiliesResult';
import { getEmittersFamilies, getEmittersEDC } from '../thunks/EmittersEDCThunks';

interface emittersEDCState {
  emitterFamilies: string[];
  currentEmitterFamily: string;
  currentEmitter: string | number;
  emittersEDC: EmitterEDC[];
  isEmitterFamiliesLoading: boolean;
  isEmittersEDCLoading: boolean;
}

const initialState: emittersEDCState = {
  emitterFamilies: [],
  currentEmitterFamily: '',
  currentEmitter: '',
  emittersEDC: [],
  isEmitterFamiliesLoading: false,
  isEmittersEDCLoading: false,
};

export const emittersEDCSlice = createSlice({
  name: 'emittersEDC',
  initialState,
  reducers: {
    setCurrentEmitterFamily(state, action: PayloadAction<string>) {
      state.currentEmitterFamily = action.payload;
    },
    setCurrentEmitter(state, action: PayloadAction<string | number>) {
      state.currentEmitter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmittersFamilies.pending.type, (state) => {
        state.isEmitterFamiliesLoading = true;
      })
      .addCase(
        getEmittersFamilies.fulfilled.type,
        (state, action: PayloadAction<EmittersFamiliesResult>) => {
          state.isEmitterFamiliesLoading = false;
          state.emitterFamilies = action.payload.GetEmitterNamesResult;
          state.currentEmitterFamily = action.payload.GetEmitterNamesResult[0];
        },
      )
      .addCase(getEmittersFamilies.rejected.type, (state) => {
        state.isEmitterFamiliesLoading = false;
      })
      .addCase(getEmittersEDC.pending.type, (state) => {
        state.isEmittersEDCLoading = true;
      })
      .addCase(getEmittersEDC.fulfilled.type, (state, action: PayloadAction<EmittersEDCResult>) => {
        state.isEmittersEDCLoading = false;
        state.emittersEDC = action.payload.GetEmittersForEnameResult.RootResults;
      })
      .addCase(getEmittersEDC.rejected.type, (state) => {
        state.isEmittersEDCLoading = false;
      });
  },
});

export const emittersEDCActions = emittersEDCSlice.actions;

export const emittersEDCReducer = emittersEDCSlice.reducer;
