import { MasterGroup, MasterGroupsResponse } from '../models/masterGroups/MasterGroupsResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMasterGroups } from '../thunks/MasterGroupsThunks';

interface MasterGroupState {
  masterGroups: MasterGroup[];
  isLoading: boolean;
}

const initialState: MasterGroupState = {
  masterGroups: [],
  isLoading: false,
};

export const masterGroupsSlice = createSlice({
  name: 'masterGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMasterGroups.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getMasterGroups.fulfilled.type,
        (state, action: PayloadAction<MasterGroupsResponse>) => {
          state.masterGroups = action.payload.GetMASTERGROUPSResult.RootResults;
          state.isLoading = false;
        },
      )
      .addCase(getMasterGroups.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const masterGroupsReducer = masterGroupsSlice.reducer;
