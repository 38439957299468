import React from 'react';
import { Box } from '@mui/system';

export const LoginLayout: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'ghostwhite',
      }}
    >
      {children}
    </Box>
  );
};
