import { customAxios } from '../../api/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginData } from '../models/auth/LoginData';
import { LoginResponse } from '../models/auth/LoginResponse';

export const login = createAsyncThunk('auth/login', async (body: LoginData, thunkAPI) => {
  try {
    const response = await customAxios.post<LoginResponse>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/Login',
      body,
    );

    if (response.data.LoginResult) {
      localStorage.setItem('user', window.btoa(encodeURIComponent(JSON.stringify(body))));
    }

    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});
