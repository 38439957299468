import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Divider,
  DialogActions,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton, SecondaryButton } from '../base';
import { CustomDialogProps } from './types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const CustomDialog: React.FC<CustomDialogProps> = ({
  children,
  open,
  handleClose,
  title,
  subtitle,
  isDisabled,
  onConfirmClick,
  isRegister = false,
  confirmButtonVariant = 'save',
  withDialogActions = true,
  ...props
}) => {
  const { t } = useTranslation();

  const TEXT_VARIANTS = useMemo(
    () => ({
      save: t('save'),
      ok: t('ok'),
      calculate: t('calculate'),
    }),
    [t]
  );

  const saveButtonText = TEXT_VARIANTS[confirmButtonVariant];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...props}
    >
      <DialogTitle id="dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="primary.main">{title}</Typography>
          {subtitle && <Typography color="primary.main">{subtitle}</Typography>}
          <IconButton onClick={handleClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <Divider sx={{ bgcolor: 'primary.main' }} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>{children}</DialogContent>
      {withDialogActions && (
        <DialogActions sx={{ px: 3 }}>
          <Grid container columnSpacing={2} rowGap={1} pb={1}>
            <Grid item sm xs={12}>
              <PrimaryButton onClick={onConfirmClick} disabled={isDisabled}>
                {saveButtonText}
              </PrimaryButton>
            </Grid>
            {!isRegister && (
              <Grid item sm xs={12}>
                <SecondaryButton onClick={handleClose}>{t('cancel')}</SecondaryButton>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDialog;
