import { Container, Box, Typography, Grid, Fade, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { CustomInput, CustomCheckbox, CustomFormControlLabel, SecondaryButton } from '../../components/base';
import { LoginLayout } from '../../layouts/LoginLayout';
import { Loader, EndUserLicense, Terms, CustomDialog } from '../../components';
import { useLoginPage } from './hooks/useLoginPage';
import { useCustomDialog } from '../../components/CustomDialog/hooks/useCustomDialog';
import { RivulisLogoPng } from '../../assets';
import { DialogModuleProps } from '../../components/types';

const UpdatedVersionModal: React.FC<DialogModuleProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
      withDialogActions={false}
      title={t('Major Update')}
    >
      <Grid container columnSpacing={2} rowGap={1}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" mb={1}>
            Dear Hydraulic Tool Users,
          </Typography>
          <Typography mb={1}>Exciting news!</Typography>
          <Typography mb={1}>
            We've released a new version of Rivulis Hydraulic Tool with major user interface changes. The update brings
            a fresh look, improved navigation, and additional features based on your feedback.
          </Typography>
          <Typography mb={1}>
            {`To get the new version, simply click on the following link: `}
            <Link href="https://ht-rivulis.com" target="_blank">
              ht-rivulis.com
            </Link>
          </Typography>
          <Typography mb={1}>
            {`We value your input, please don't hesitate to reach out if you have any questions or encounter any issues at `}
            <Link href="mailto:ht_support@rivulis.com">ht_support@rivulis.com</Link>
          </Typography>
          <Typography mb={1}>
            Please note that the previous version of the app is no longer supported. Kindly refrain from using this
            outdated link.
          </Typography>
          <Typography>Thank you for your continued support!</Typography>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export const LoginPage = () => {
  const { t } = useTranslation();
  const { openDialog: openAdditionalTerms, toggleDialog: toggleAdditionalTerms } = useCustomDialog();
  const { openDialog: openUpdatedVersion, toggleDialog: toggleUpdatedVersion } = useCustomDialog(true);
  // const { onSubmit, isLoading, onRegisterClick, register, handleSubmit, errors } = useLoginPage();

  return (
    <LoginLayout>
      <EndUserLicense open={openAdditionalTerms} handleClose={toggleAdditionalTerms} />
      <UpdatedVersionModal open={openUpdatedVersion} handleClose={toggleUpdatedVersion} />

      <Fade in timeout={700} mountOnEnter unmountOnExit>
        <Container component="main" maxWidth="md" sx={{ margin: 'auto' }}>
          <Typography color="primary" fontWeight={700} variant="h4" align="center">
            New Hydraulic Tool Version
          </Typography>
          <Typography variant="h5" align="center">
            Click here for the new version:{' '}
            <Link target="_blank" href="https://ht-rivulis.com">
              ht-rivulis.com
            </Link>
          </Typography>
          {/* <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5" component="h1">
                {t('title')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                alt="logotype"
                src={RivulisLogoPng}
                sx={{ width: '100%', display: 'block', height: '100%' }}
              />
            </Grid>
          </Grid>

          <Grid container component="form" onSubmit={handleSubmit(onSubmit)} noValidate mt={3} rowGap={2}>
            <Grid item xs={12}>
              <CustomInput
                {...register('user')}
                required
                autoFocus
                label={`${t('Username')}`}
                error={!!errors.user}
                helperText={errors.user?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                {...register('password')}
                required
                type="password"
                label={`${t('Password')}`}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Terms toggleAdditionalTerms={toggleAdditionalTerms} />
            </Grid>
            <Grid item xs={12}>
              <CustomFormControlLabel
                control={<CustomCheckbox {...register('rememberMe')} />}
                label={`${t('Remember me')}`}
              />
            </Grid>
            <Grid container columnSpacing={2} rowGap={1}>
              <Grid item sm xs={12}>
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  loadingIndicator={<Loader color="secondary" size={16} />}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t('login')}
                </LoadingButton>
              </Grid>
              <Grid item sm xs={12}>
                <SecondaryButton onClick={onRegisterClick}>{t('register')}</SecondaryButton>
              </Grid>
            </Grid>
          </Grid> */}
        </Container>
      </Fade>
    </LoginLayout>
  );
};
