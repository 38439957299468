import { customAxios } from '../../api/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SaveUnitsResponse } from './../models/units/SaveUnitsResponse';
import { SaveUnitsData } from './../models/units/SaveUnitsData';
import { UnitsData } from './../models/units/UnitsData';
import { UnitsResponse } from '../models/units/UnitsResponse';

export const getUnits = createAsyncThunk('units/getUnits', async (params: UnitsData, thunkAPI) => {
  try {
    const response = await customAxios.get<UnitsResponse>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetUnits',
      { params },
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const saveUnits = createAsyncThunk(
  'units/saveUnits',
  async (body: SaveUnitsData, thunkAPI) => {
    try {
      const response = await customAxios.post<SaveUnitsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/SaveUnits',
        body,
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
