import { getLateralGroups, getMainlineGroups, getManifoldGroups } from './../thunks/GroupsThunks';
import { Group, GroupsResponse } from '../models/groups/GroupsResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEmitterGroups } from '../thunks/GroupsThunks';

interface GroupsState {
  emitterGroups: Group[];
  lateralGroups: Group[];
  mainlineGroups: Group[];
  manifoldGroups: Group[];
  isEmitterGroupsLoading: boolean;
  isLateralGroupsLoading: boolean;
  isMainlineGroupsLoading: boolean;
  isManifoldGroupsLoading: boolean;
}

const initialState: GroupsState = {
  emitterGroups: [],
  lateralGroups: [],
  mainlineGroups: [],
  manifoldGroups: [],
  isEmitterGroupsLoading: false,
  isLateralGroupsLoading: false,
  isMainlineGroupsLoading: false,
  isManifoldGroupsLoading: false,
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmitterGroups.pending.type, (state) => {
        state.isEmitterGroupsLoading = true;
      })
      .addCase(getEmitterGroups.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.emitterGroups = action.payload.GetGroupTypesResult.RootResults;
        state.isEmitterGroupsLoading = false;
      })
      .addCase(getEmitterGroups.rejected.type, (state) => {
        state.isEmitterGroupsLoading = false;
      })
      .addCase(getLateralGroups.pending.type, (state) => {
        state.isLateralGroupsLoading = true;
      })
      .addCase(getLateralGroups.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.lateralGroups = action.payload.GetGroupTypesResult.RootResults;
        state.isLateralGroupsLoading = false;
      })
      .addCase(getLateralGroups.rejected.type, (state) => {
        state.isLateralGroupsLoading = false;
      })
      .addCase(getMainlineGroups.pending.type, (state) => {
        state.isMainlineGroupsLoading = true;
      })
      .addCase(getMainlineGroups.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.mainlineGroups = action.payload.GetGroupTypesResult.RootResults;
        state.isMainlineGroupsLoading = false;
      })
      .addCase(getMainlineGroups.rejected.type, (state) => {
        state.isMainlineGroupsLoading = false;
      })
      .addCase(getManifoldGroups.pending.type, (state) => {
        state.isManifoldGroupsLoading = true;
      })
      .addCase(getManifoldGroups.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.manifoldGroups = action.payload.GetGroupTypesResult.RootResults;
        state.isManifoldGroupsLoading = false;
      })
      .addCase(getManifoldGroups.rejected.type, (state) => {
        state.isManifoldGroupsLoading = false;
      });
  },
});

export const groupsReducer = groupsSlice.reducer;
