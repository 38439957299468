import { customAxios } from '../../api/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectsResponse } from '../models/projects/ProjectsResponse';
import { ProjectsData } from '../models/projects/ProjectsData';
import { DeleteProjectResponse } from './../models/projects/DeleteProjectResponse';
import { DeleteProjectData } from './../models/projects/DeleteProjectData';
import { projectsActions } from '../reducers/ProjectsSlice';

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async (params: ProjectsData, thunkAPI) => {
    try {
      const response = await customAxios.get<ProjectsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetProjects',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (body: DeleteProjectData, thunkAPI) => {
    try {
      const response = await customAxios.post<DeleteProjectResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/DeleteProject',
        body,
      );
      thunkAPI.dispatch(projectsActions.filterDeletedProject(body.projectId));
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
