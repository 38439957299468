import { CatalogsResponse } from '../models/catalogs/CatalogsResponse';
import { getEmitters } from './../thunks/CatalogsThunks';
import { CatalogItem } from '../models/catalogs/CatalogsResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface emittersState {
  emitters: CatalogItem[];
  isLoading: boolean;
}

const initialState: emittersState = {
  emitters: [],
  isLoading: false,
};

export const emittersSlice = createSlice({
  name: 'emitters',
  initialState,
  reducers: {
    setEmitters(state, action: PayloadAction<CatalogItem[]>) {
      state.emitters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmitters.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmitters.fulfilled.type, (state, action: PayloadAction<CatalogsResponse>) => {
        state.isLoading = false;
        state.emitters = action.payload.GetCatalogsResult.RootResults;
      })
      .addCase(getEmitters.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const emittersActions = emittersSlice.actions;

export const emittersReducer = emittersSlice.reducer;
