import { Button, ButtonProps } from '@mui/material';
import React from 'react';

const SecondaryButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button color="primary" fullWidth variant="outlined" {...props}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
