import { toast } from 'react-toastify';
import { customAxios } from '../../api/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectData } from './../models/projectData/ProjectData';
import { ProjectDataResponse } from '../models/projectData/ProjectDataResponse';
import { SaveProject } from '../models/projectData/SaveProject';
import { SaveProjectResponse } from '../models/projectData/SaveProjectResponse';
import { SaveProjectAs } from '../models/projectData/SaveProjectAs';
import { SaveProjectAsResponse } from '../models/projectData/SaveProjectAsResponse';

export const getProjectData = createAsyncThunk(
  'projectData/getProjectData',
  async (params: ProjectData, thunkAPI) => {
    try {
      const response = await customAxios.get<ProjectDataResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetProjectData',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const saveProject = createAsyncThunk(
  'projectData/saveProjectData',
  async (body: SaveProject, thunkAPI) => {
    try {
      const response = await customAxios.post<SaveProjectResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/SaveProject',
        body,
      );
      if (response.data.SaveProjectResult) {
        toast.success('Project saved successfully');
      }
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const saveProjectAs = createAsyncThunk(
  'projectData/saveProjectDataAs',
  async (body: SaveProjectAs, thunkAPI) => {
    try {
      const response = await customAxios.post<SaveProjectAsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/AddProject',
        body,
      );
      if (response.data.AddProjectResult) {
        toast.success(`Project saved successfully as ${body.projectName}`);
      }
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
