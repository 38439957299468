import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDCCalcResult } from '../models/EDCCalculations/EDCCalcResult';

interface CalculationsState {
  edcCalcResult: EDCCalcResult;
}

const initialState: CalculationsState = {
  edcCalcResult: {} as EDCCalcResult,
};

export const EDCCalculationsSlice = createSlice({
  name: 'EDCCalculations',
  initialState,
  reducers: {
    setEDCCalcResult(state, action: PayloadAction<EDCCalcResult>) {
      state.edcCalcResult = action.payload;
    },
  },
});

export const EDCCalculationsActions = EDCCalculationsSlice.actions;

export const EDCCalculationsReducer = EDCCalculationsSlice.reducer;

/*EDCinput: {
          Placement: number;
          cbEDCEmitter: { SelectedItem: Emitter } | null;
          txtBedWidth: number;
          txtLateralSpacing: number;
          txtEmitterSpacing: number;
          txtSprinklerCount: number;
          EdgeType: number;
          dEDGEPOSITIONX: number;
          dEDGEPOSITIONY: number;
          range: boolean;
          _ShowGrowth: boolean;
          BetweenPlants: number;
          BetweenRows: number;
          PlantDiameter: number;
          StaggeredRows: boolean;
          GroupPlantRows: boolean;
          RowsinGroup: number;
          BetweenGroups: number;
          InRows: number;
          OffRows: number;
          CenterPlantsbetweenlaterals: boolean;
          Graph: boolean;
          mouseClickPositionY1: number;
          mouseClickPositionY2: number;
        }; */
