import {
  getUserTemplateLaterals,
  getUserTemplateMainlines,
  getUserTemplateManifolds,
} from './../thunks/UserTemplateThunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogItem, CatalogsResponse } from '../models/catalogs/CatalogsResponse';
import { getUserTemplateEmitters } from '../thunks/UserTemplateThunks';
import { CatalogsByFilterResponse } from '../models/catalogs/CatalogsByFilterResponse';
import { CatalogsByClassResponse } from '../models/catalogs/CatalogsByClassResponse';

interface UserTemplateState {
  emitters: CatalogItem[];
  laterals: CatalogItem[];
  mainlines: CatalogItem[];
  manifolds: CatalogItem[];
  isEmittersLoading: boolean;
  isLateralsLoading: boolean;
  isMainlinesLoading: boolean;
  isManifoldsLoading: boolean;
}

const initialState: UserTemplateState = {
  emitters: [],
  laterals: [],
  mainlines: [],
  manifolds: [],
  isEmittersLoading: false,
  isLateralsLoading: false,
  isMainlinesLoading: false,
  isManifoldsLoading: false,
};

export const userTemplateSlice = createSlice({
  name: 'userTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserTemplateEmitters.pending.type, (state) => {
        state.isEmittersLoading = true;
      })
      .addCase(
        getUserTemplateEmitters.fulfilled.type,
        (state, action: PayloadAction<CatalogsResponse>) => {
          state.emitters = action.payload.GetCatalogsResult.RootResults;
          state.isEmittersLoading = false;
        },
      )
      .addCase(getUserTemplateEmitters.rejected.type, (state) => {
        state.isEmittersLoading = false;
      })
      .addCase(getUserTemplateLaterals.pending.type, (state) => {
        state.isLateralsLoading = true;
      })
      .addCase(
        getUserTemplateLaterals.fulfilled.type,
        (state, action: PayloadAction<CatalogsByFilterResponse>) => {
          state.laterals = action.payload.GetCatalogPerDiaClassFlowSpacingResult.RootResults;
          state.isLateralsLoading = false;
        },
      )
      .addCase(getUserTemplateLaterals.rejected.type, (state) => {
        state.isLateralsLoading = false;
      })
      .addCase(getUserTemplateManifolds.pending.type, (state) => {
        state.isManifoldsLoading = true;
      })
      .addCase(
        getUserTemplateManifolds.fulfilled.type,
        (state, action: PayloadAction<CatalogsByClassResponse>) => {
          state.manifolds = action.payload.GetCatalogsByClassResult.RootResults;
          state.isManifoldsLoading = false;
        },
      )
      .addCase(getUserTemplateManifolds.rejected.type, (state) => {
        state.isManifoldsLoading = false;
      })
      .addCase(getUserTemplateMainlines.pending.type, (state) => {
        state.isMainlinesLoading = true;
      })
      .addCase(
        getUserTemplateMainlines.fulfilled.type,
        (state, action: PayloadAction<CatalogsByClassResponse>) => {
          state.mainlines = action.payload.GetCatalogsByClassResult.RootResults;
          state.isMainlinesLoading = false;
        },
      )
      .addCase(getUserTemplateMainlines.rejected.type, (state) => {
        state.isMainlinesLoading = false;
      });
  },
});

export const userTemplateReducer = userTemplateSlice.reducer;
