import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../base';
import { CustomDialog } from '..';
import { DialogModuleProps } from '../types';

const EndUserLicense: React.FC<DialogModuleProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      maxWidth="lg"
      open={open}
      handleClose={handleClose}
      withDialogActions={false}
      title={t('END USER LICENSE AGREEMENT')}>
      <Grid container columnSpacing={2} rowGap={1}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            END USER LICENSE AGREEMENT
          </Typography>
          <Typography>
            This End User License Agreement ("Agreement") is made between RIVULIS IRRIGATION, ("WE"
            or "RIVULIS IRRIGATION") and any person who receives RIVULIS IRRIGATION's irrigation
            design software ("YOU" or "USER"). The term "Software" means RIVULIS IRRIGATION's
            irrigation design software and also includes any upgrades, modified versions or updates
            of the Software licensed to YOU by RIVULIS IRRIGATION. RIVULIS IRRIGATION and USER are
            collectively referred to as the "Parties."
          </Typography>
          <Typography>
            NOTICE TO USER: THIS IS A CONTRACT. PLEASE READ THIS AGREEMENT CAREFULLY. BY CLICKING
            THE "I ACCEPT" BUTTON OR ACCESSING, DOWNLOADING, INSTALLING OR USING ANY PART OF THE
            SOFTWARE, YOU EXPRESSLY AGREE TO AND CONSENT TO BE BOUND BY ALL OF THE TERMS OF THIS
            AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, PLEASE SELECT THE
            "I DO NOT ACCEPT" BUTTON, IN WHICH CASE RIVULIS IRRIGATION WILL PROMPTLY CANCEL THIS
            TRANSACTION AND YOU MAY NOT ACCESS, DOWNLOAD, INSTALL, OR USE ANY PART OF THE SOFTWARE.
            IN ADDITION, IF YOU DO NOT AGREE WITH ALL OF THE TERMS HEREIN, PLEASE RETURN THE
            SOFTWARE TO RIVULIS IRRIGATION.
          </Typography>

          <Typography>
            1. NON-EXCLUSIVE LICENSE. Subject to the following terms and conditions, RIVULIS
            IRRIGATION hereby grants YOU a personal, nontransferable, non-assignable, terminable,
            nonexclusive license, without the right to sublicense, to download and use a single copy
            of the Software only for your internal non-commercial purposes.
          </Typography>
          <Typography>
            2. RESTRICTIONS. To the extent permitted by applicable law, YOU agree not to (i) sell,
            resell, license, exploit or otherwise distribute the Software to any third party; (ii)
            copy, modify, reverse engineer, map, decompile, enhance, or make derivative works,
            translations, or compilations or otherwise derive the source code, internal structure,
            organization or any other aspect of the Software or any part thereof or directly or
            indirectly aid, abet or permit others to do so; (iii) remove, alter or render illegible
            any copyright notice or other proprietary notices of RIVULIS IRRIGATION contained in the
            Software; (iv) provide use of the Software in a computer service business, rental or
            commercial timesharing arrangement; and (v) develop methods to enable unauthorized
            parties to use the Software.
          </Typography>
          <Typography>
            3. INTELLECTUAL PROPERTY RIGHTS. RIVULIS IRRIGATION exclusively owns all worldwide
            right, title and interest in and to the Software and to all United States and foreign
            copyrights, patents, trademarks, trade secrets and all other intellectual property
            rights related thereto. This Agreement does not grant YOU any intellectual property
            rights in the Software. Any unauthorized modifications, derivative works, translations,
            or any other intellectual property, created directly or indirectly using or referring to
            the Software, or components thereof, or enhancements of the Software, shall belong
            exclusively to RIVULIS IRRIGATION and, as consideration for the license granted herein,
            you hereby assign any and all rights in them (and agree to forego enforcement of any
            moral rights) to RIVULIS IRRIGATION.
          </Typography>
          <Typography>
            4. NO WARRANTY. THE SOFTWARE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT
            ANY WARRANTY OF ANY KIND FROM RIVULIS IRRIGATION. WITHOUT LIMITING THE GENERALITY OF THE
            FOREGOING, RIVULIS IRRIGATION MAKES NO WARRANTY THAT (i) THE SOFTWARE WILL MEET YOUR
            REQUIREMENTS, (ii) THE SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
            (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SOFTWARE WILL BE ACCURATE OR
            RELIABLE, (iv) THE EFFICIENCY, ACCURACY OR USEFULNESS OF ANY IRRIGATION DESIGNS OR OTHER
            MATERIAL OBTAINED BY YOU THROUGH THE USE OF THE SOFTWARE, AND/OR (v) ANY ERRORS IN THE
            SOFTWARE WILL BE CORRECTED. TO THE EXTENT PERMITTED BY APPLICABLE LAW, RIVULIS
            IRRIGATION EXPRESSLY DISCLAIMS ALL WARRANTIES REGARDING THE SOFTWARE, WHETHER EXPRESS,
            IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF
            MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF THIRD PARTY
            RIGHTS, OR BASED ON COURSE OF CONDUCT OR TRADE CUSTOM OR USAGE. ALL USE OF THE SOFTWARE
            IS ENTIRELY AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEMS, IRRIGATION SYSTEMS, CROPS, SOIL, OR ANY OTHER PROPERTY OR ANY OTHER
            PROBLEMS THAT RESULT FROM THE DOWNLOAD OR USE OF THE SOFTWARE.
          </Typography>
          <Typography>
            5. INDEMNITY. YOU agree to indemnify, defend and hold RIVULIS IRRIGATION harmless from
            all claims, damages and other liabilities, including without limitation reasonable
            attorneys' fees and costs, whether or not a lawsuit or other proceeding is filed, that
            arise out of or relates to (i) YOUR download of the Software; (ii) YOUR or any
            affiliated or related third party's use of the Software in any way; (iii) YOUR or any
            affiliated or related third party's violation of RIVULIS IRRIGATION' rights and/or the
            rights of any other third party; and/or (iv) all property damage caused by YOUR or any
            affiliated or related third party's use of or reliance on the Software.
          </Typography>
          <Typography>
            6. LIMITATION OF LIABILITY. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT BE
            RESPONSIBLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL
            OR PUNITIVE DAMAGES OR LOSSES, REGARDLESS OF THE NATURE OF THE CLAIM, WHETHER
            FORESEEABLE OR NOT, THAT ARE IN ANY WAY RELATED TO THIS AGREEMENT, THE BREACH THEREOF,
            THE USE OR INABILITY TO USE THE SOFTWARE, THE RESULTS GENERATED FROM THE USE OF THE
            SOFTWARE, LOSS OF GOODWILL OR PROFITS, AND/OR FROM ANY OTHER CAUSE WHATSOEVER. SOME
            JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN DAMAGES, SO IN THAT CASE, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU.
          </Typography>
          <Typography>
            7. CONSUMER PROTECTION LAWS. THE SOFTWARE IS A BUSINESS PRODUCT, THE APPLICATION OF
            WHICH IS COMMERCIAL, RATHER THAN CONSUMER-ORIENTED, IN NATURE. IN ACCEPTING THIS
            AGREEMENT, YOU RECOGNIZE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THAT
            CONSUMER PROTECTION LAWS IN THE TERRITORY DO NOT APPLY.
          </Typography>
          <Typography>
            8. TERMINATION. This Agreement shall automatically terminate (i) upon failure by YOU to
            comply with its terms; and (ii) upon notice by RIVULIS IRRIGATION that it is eliminating
            or no longer distributing the Software. This Agreement may also be terminated by RIVULIS
            IRRIGATION, for any reason, by a specific written notice to YOU.
          </Typography>
          <Typography>
            9. GOVERNING LAW AND GENERAL PROVISIONS. This Agreement will be governed by the laws of
            the State of California, U.S.A., excluding the application of its choice or conflicts of
            law rules. Disputes that may arise in connection with the subject matter, breach or
            interpretation hereof shall be exclusively resolved by arbitration in San Marcos,
            California, U.S.A. before a single arbitrator appointed pursuant to the California Code
            of Civil Procedure. If any part of this Agreement is found void or unenforceable, it
            will not affect the validity of the balance of the Agreement, which shall remain valid
            and enforceable according to its terms. YOU agree that the Software will not be shipped,
            transferred or exported into any country or used in any manner prohibited by the United
            States Export Administration Act or any other export laws, restrictions or regulations.
            This Agreement may only be modified in writing signed by an authorized officer of
            RIVULIS IRRIGATION. This Agreement constitutes the entire Agreement between RIVULIS
            IRRIGATION and YOU regarding the subject matter hereof, and supercedes all other
            understandings, whether oral or written, regarding the subject matter hereof. In any
            arbitration, action or other proceeding relating to this Agreement, or the breach or
            interpretation hereof, the prevailing Party shall be entitled to reasonable attorney
            fees and costs, as determined by the trier of fact.
          </Typography>
          <Typography variant="body2" align="center">
            This Software is © 2003, RIVULIS IRRIGATION., all rights reserved, under the copyright
            laws of the United States.
          </Typography>
          <Typography>
            IF YOU AGREE TO THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT , PLEASE
            SELECT "I ACCEPT" IN THE SPACE PROVIDED BELOW AND PROCEED WITH THE DOWNLOAD,
            INSTALLATION AND USE OF THE SOFTWARE
          </Typography>
          <Typography>
            IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT,
            PLEASE SELECT "I DO NOT ACCEPT." SELECTING "I ACCEPT" WILL BE THE LEGAL EQUIVALENT OF
            YOUR SIGNATURE ON A WRITTEN CONTRACT, AND EQUALLY BINDING. YOU MUST AGREE TO THESE TERMS
            AND CONDITIONS IN ORDER TO DOWNLOAD, INSTALL AND USE THE SOFTWARE. USER HAS READ,
            UNDERSTANDS AND AGREES TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.
          </Typography>
        </Grid>
        <Grid item sm xs={12}>
          <PrimaryButton onClick={handleClose}>{t('I ACCEPT')}</PrimaryButton>
        </Grid>
        <Grid item sm xs={12}>
          <SecondaryButton onClick={handleClose}>{t('I DO NOT ACCEPT')}</SecondaryButton>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default EndUserLicense;
