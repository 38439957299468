import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translations from './resources.json';

const keys = Object.keys(translations);

const getTranslationsByLang = (lang: keyof typeof translations._date) => {
  const result: Record<string, string> = {};
  for (const key of keys) {
    result[key] = (translations as any)[key][lang];
  }

  return result;
};

const resources = {
  en: {
    translation: {
      ...getTranslationsByLang('en'),
    },
  },
  es: {
    translation: {
      ...getTranslationsByLang('es'),
    },
  },

  'he-IL': {
    translation: {
      ...getTranslationsByLang('he-IL'),
    },
  },
  fr: {
    translation: {
      ...getTranslationsByLang('fr'),
    },
  },
  pt: {
    translation: {
      ...getTranslationsByLang('pt'),
    },
  },

  it: {
    translation: {
      ...getTranslationsByLang('it'),
    },
  },
  tr: {
    translation: {
      ...getTranslationsByLang('tr'),
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
