import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { login } from '../thunks/AuthThunks';
import { LoginResponse } from '../models/auth/LoginResponse';
import { LoginBody } from '../../pages/Login/types';

interface UserState {
  isAuthenticated: boolean;
  username: string;
  isLoading: boolean;
}

const initialState: UserState = {
  isAuthenticated: false,
  username: '',
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.username = '';
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled.type, (state, action: PayloadAction<LoginResponse>) => {
        state.isLoading = false;
        state.isAuthenticated = action.payload.LoginResult;

        if (action.payload.LoginResult) {
          const userJson = localStorage.getItem('user')!;
          const user = JSON.parse(decodeURIComponent(window.atob(userJson))) as LoginBody;

          state.username = user.user;
        } else {
          toast.error('Wrong login credentials');
        }
      })
      .addCase(login.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;
