import { useState, useCallback } from 'react';

export const useCustomDialog = (show: boolean = false) => {
  const [openDialog, setOpenDialog] = useState(show);

  const toggleDialog = useCallback(() => {
    setOpenDialog((prev) => !prev);
  }, []);

  return { openDialog, toggleDialog };
};
