import { CatalogsByClassResponse } from '../models/catalogs/CatalogsByClassResponse';
import { getManifolds } from './../thunks/CatalogsThunks';
import { CatalogItem } from '../models/catalogs/CatalogsResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface manifoldsState {
  manifolds: CatalogItem[];
  isLoading: boolean;
}

const initialState: manifoldsState = {
  manifolds: [],
  isLoading: false,
};

export const manifoldsSlice = createSlice({
  name: 'manifolds',
  initialState,
  reducers: {
    setManifolds(state, action: PayloadAction<CatalogItem[]>) {
      state.manifolds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManifolds.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getManifolds.fulfilled.type,
        (state, action: PayloadAction<CatalogsByClassResponse>) => {
          state.isLoading = false;
          state.manifolds = action.payload.GetCatalogsByClassResult.RootResults;
        },
      )
      .addCase(getManifolds.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const manifoldsActions = manifoldsSlice.actions;

export const manifoldsReducer = manifoldsSlice.reducer;
