import { CatalogsByClassResponse } from '../models/catalogs/CatalogsByClassResponse';
import { getMainlines } from './../thunks/CatalogsThunks';
import { CatalogItem } from '../models/catalogs/CatalogsResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface mainlinesState {
  mainlines: CatalogItem[];
  isLoading: boolean;
}

const initialState: mainlinesState = {
  mainlines: [],
  isLoading: false,
};

export const mainlinesSlice = createSlice({
  name: 'mainlines',
  initialState,
  reducers: {
    setMainlines(state, action: PayloadAction<CatalogItem[]>) {
      state.mainlines = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainlines.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getMainlines.fulfilled.type,
        (state, action: PayloadAction<CatalogsByClassResponse>) => {
          state.isLoading = false;
          state.mainlines = action.payload.GetCatalogsByClassResult.RootResults;
        },
      )
      .addCase(getMainlines.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const mainlinesActions = mainlinesSlice.actions;

export const mainlinesReducer = mainlinesSlice.reducer;
