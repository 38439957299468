import { Box, Fade, FormControl, Grid, MenuItem, RadioGroup, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  CustomInput,
  CustomSelect,
  CustomSelectLabel,
  CustomFormControlLabel,
  PrimaryButton,
  SecondaryButton,
  CustomRadio,
  CustomCheckbox,
} from '../../components/base';
import { useActions, useAppDispatch, useAppSelector } from '../../store/hooks/redux';
import { MANIFOLD_HEADLOSS_CALCULATION } from '../../constants';
import { PageProps } from '../types';
import { ManifoldDesignForm } from './types';
import { CatalogItem } from '../../store/models/catalogs/CatalogsResponse';
import { updateManifoldVelocities } from '../../calculation/ManifoldDesign/UpdateManifoldVelocities';
import { calculateFlow, calculateNumOfRows, calculateTotalLength } from './calculation';
import { manifoldCalculate } from '../../calculation/ManifoldDesign/ManifoldCalculate';
import { toast } from 'react-toastify';
import { useCustomDialog } from '../../components/CustomDialog/hooks/useCustomDialog';
import { parseSlopes } from '../../components/LateralSlopes/helpers/parseSlopes';
import { ManifoldCalcResult } from '../../store/models/calculations/ManifoldCalcResult';
import { ManifoldSlopes, ManifoldReport, ProjectName, ModuleImage } from '../../components';
import { ProjectDetails } from '../../store/models/projectData/ProjectDataResponse';
import { FilterManifoldCatalogs } from '../../calculation/ManifoldDesign/FilterManifoldCatalogs';
import { ConvertUnit } from '../../calculation/unitConverter';
import { Math_round } from '../../calculation/mathRound';
import { METRIC_DEFAULTS } from '../../components/Units/constants';

export const ManifoldDesignPage: React.FC<PageProps> = ({ toggleUserTemplate, openProjectName, toggleProjectName }) => {
  const dispatch = useAppDispatch();
  const {
    setManifoldCalcResult,
    saveProjectData,
    setManifoldSlopes,
    resetManifoldSavedInputs,
    saveCurrentProjectState,
  } = useActions();
  const { savedInputs } = useAppSelector((state) => state.calculationsReducer);
  const { units } = useAppSelector((state) => state.unitsReducer);
  const { username } = useAppSelector((state) => state.authReducer);
  const { isProjectExist } = useAppSelector((state) => state.projectsReducer);
  const { projectData, projectDataLoaded, currentProjectState } = useAppSelector((state) => state.projectDataReducer);
  const { manifolds } = useAppSelector((state) => state.manifoldsReducer);
  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    reset,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<ManifoldDesignForm>({
    defaultValues: {
      headlossCalculation: '',
      classType: '',
      calculationType: 'totalSubmainLen',
      pipe1: '',
      pipe2: '',
      pipe3: '',
    },
  });

  const { openDialog: openSlopes, toggleDialog: toggleSlopes } = useCustomDialog();
  const { openDialog: openManifoldReport, toggleDialog: toggleManifoldReport } = useCustomDialog();

  const classType = watch('classType');
  const headlossCalculation = watch('headlossCalculation');
  const isBothSides = watch('bothSides');
  const isShowReport = watch('showReport');
  const calculationType = watch('calculationType');
  const numberOfRows = watch('numberOfRowsValue');
  const totalSubmainLength = watch('totalSubmainLengthValue');
  const flow1 = watch('flow1');
  const flow2 = watch('flow2');
  const flow = watch('flow');
  const pipe1 = watch('pipe1');
  const pipe2 = watch('pipe2');
  const pipe3 = watch('pipe3');
  const pipe1Dia = watch('pipe1Dia');
  const pipe2Dia = watch('pipe2Dia');
  const pipe3Dia = watch('pipe3Dia');

  const classTypeFilter = (item: CatalogItem) => (classType ? item.Class === classType : true);

  const classTypes = [...new Set(manifolds.map((item) => item.Class))];

  const manifoldsFiltered = manifolds.filter((item) => classTypeFilter(item));

  const currentPipe1 = manifoldsFiltered.find((item) => item.CATLOG === pipe1) as CatalogItem;
  const currentPipe2 = manifoldsFiltered.find((item) => item.CATLOG === pipe2) as CatalogItem;
  const currentPipe3 = manifoldsFiltered.find((item) => item.CATLOG === pipe3) as CatalogItem;

  const onSubmit: SubmitHandler<ManifoldDesignForm> = (data) => {
    const slopes = parseSlopes(projectData.ManifoldSlopes ?? '').map((item) => ({
      Length: item.length,
      Slope: item.slope,
      SlopeDirection: item.direction === 'D' ? 'Downhill' : 'Uphill',
    }));

    const result = manifoldCalculate(
      {
        Flow1: +data.flow1,
        Flow2: +data.flow2,
        ManifoldSpacing: +data.manifoldSpacing,
        NumberOfRows: +data.numberOfRowsValue,
        InternalDiameter1: +data.pipe1Dia,
        InternalDiameter2: +data.pipe2Dia,
        InternalDiameter3: +data.pipe3Dia,
        ManifoldHWCoef: +data.hwCoef,
        ManifoldPipe1: +currentPipe1.HWCOF,
        ManifoldPipe2: +currentPipe2.HWCOF,
        ManifoldPipe3: +currentPipe3.HWCOF,
        Pipe1Length: +data.length1,
        Pipe2Length: +data.length2,
        Pipe3Length: +data.length3,
        TotalSubmainLength: +data.totalSubmainLengthValue,
        DistanceTo1stLateral: +data.disTo1stLateral,
        MultiSlopesManifold: slopes,
        ManifoldMaxVelocity: +data.maxVelocity,
        ValvePressure: +data.valvePressure,
        ValveHeadloss: +data.totalHeadloss,
        LateralInletPressure: +data.lateralInletPr,
        ManifoldAllowedHL: +data.allowableHL,
        ManifoldHeadlossCalculation: data.headlossCalculation,
      },
      units,
      data.bothSides,
      data.calcLengths,
      data.showReport
    ) as ManifoldCalcResult;

    dispatch(
      saveProjectData({
        ValvePressure: result.ValvePressure,
        ManifoldHeadloss: result.SubmainAllowableHeadloss,
        ManifoldPipe1Length: result.length1,
        ManifoldPipe2Length: result.length2,
        ManifoldPipe3Length: result.length3,
        MainlineFlow: +data.flow,
      })
    );

    dispatch(
      saveCurrentProjectState({
        firstLateral: result.FirstLateralPressure,
        lastLateral: result.LastLateralPressure,
      })
    );

    if (result.error) {
      toast.error(t(`${result.error}`));
      return;
    } else {
      dispatch(setManifoldCalcResult(result));
    }

    if (isShowReport && result.reportResult) {
      toggleManifoldReport();
    }

    setValue('valvePressure', result.ValvePressure);
    setValue('firstLateral', result.FirstLateralPressure);
    setValue('lastLateral', result.LastLateralPressure);
    setValue('totalHeadloss', result.SubmainAllowableHeadloss);
    setValue('length1', result.length1);
    setValue('length2', result.length2);
    setValue('length3', result.length3);
    setValue('velocity1', result.velocity1);
    setValue('velocity2', result.velocity2);
    setValue('velocity3', result.velocity3);

    dispatch(
      saveCurrentProjectState({
        manifoldVelocity1: result.velocity1,
        manifoldVelocity2: result.velocity2,
        manifoldVelocity3: result.velocity3,
      })
    );

    if (result.velocity1 && result.velocity1 > +data.maxVelocity) {
      setError('velocity1', {});
    } else {
      clearErrors('velocity1');
    }
    if (result.velocity2 && result.velocity2 > +data.maxVelocity) {
      setError('velocity2', {});
    } else {
      clearErrors('velocity2');
    }
    if (result.velocity3 && result.velocity3 > +data.maxVelocity) {
      setError('velocity3', {});
    } else {
      clearErrors('velocity3');
    }
  };

  const resetForm = () => {
    dispatch(setManifoldSlopes(''));
    dispatch(resetManifoldSavedInputs());
    dispatch(
      saveProjectData({
        ManifoldAllowableHeadloss: 0,
        ManifoldMaximumVelocity: 0,
        ManifoldHWCoef: 0,
        SubmainClass: '',
        FirstLateralDistance: 0,
        LateralSpacing: 0,
        TotalManifoldLength: 0,
        TotalRows: 0,
        ManifoldPipe1Length: 0,
        ManifoldPipe2Length: 0,
        ManifoldPipe3Length: 0,
        ManifoldPipe1: manifoldsFiltered[0].CATLOG,
        ManifoldPipe2: manifoldsFiltered[0].CATLOG,
        ManifoldPipe3: manifoldsFiltered[0].CATLOG,
        ValvePressure: 0,
        ManifoldHeadloss: 0,
      })
    );
    reset({
      ...getValues(),
      hwCoef: 0,
      allowableHL: 0,
      maxVelocity: 0,
      classType: '',
      disTo1stLateral: 0,
      manifoldSpacing: 0,
      totalSubmainLengthValue: 0,
      numberOfRowsValue: 0,
      length1: 0,
      length2: 0,
      length3: 0,
      valvePressure: 0,
      totalHeadloss: 0,
      pipe1Dia: Math_round(ConvertUnit(currentPipe1.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null), 3),
      pipe2Dia: Math_round(ConvertUnit(currentPipe2.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null), 3),
      pipe3Dia: Math_round(ConvertUnit(currentPipe3.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null), 3),
      velocity1: 0,
      velocity2: 0,
      velocity3: 0,
      firstLateral: 0,
      lastLateral: 0,
    });
  };

  useEffect(() => {
    if (currentPipe1?.CATLOG !== projectData.ManifoldPipe1) {
      setValue('pipe1', projectData.ManifoldPipe1);
    }
    if (currentPipe2?.CATLOG !== projectData.ManifoldPipe2) {
      setValue('pipe2', projectData.ManifoldPipe2);
    }
    if (currentPipe3?.CATLOG !== projectData.ManifoldPipe3) {
      setValue('pipe3', projectData.ManifoldPipe3);
    }
  }, [manifolds]);

  useEffect(() => {
    if (projectDataLoaded) {
      reset({
        calculationType: 'totalSubmainLen',
        headlossCalculation: projectData.ManifoldHeadlossCalc ?? 'H.W',
        hwCoef: projectData.ManifoldHWCoef ?? 0,
        allowableHL: projectData.ManifoldAllowableHeadloss ?? 0,
        maxVelocity: projectData.ManifoldMaximumVelocity ?? 0,
        bothSides: projectData.IsBothSides ?? false,
        flow1: projectData.Flow1 ?? 0,
        flow2: projectData.Flow2 ?? 0,
        lateralInletPr: projectData.LateralInletPressure ?? 0,
        flow: projectData.TotalFlow ?? 0,
        disTo1stLateral: projectData.FirstLateralDistance ?? 0,
        manifoldSpacing: projectData.ManifoldSpacing ?? 0,
        totalSubmainLengthValue: projectData.TotalManifoldLength ?? 0,
        numberOfRowsValue: projectData.TotalRows ?? 0,
        classType: projectData.SubmainClass ?? '',
        pipe1: projectData.ManifoldPipe1,
        pipe2: projectData.ManifoldPipe2,
        pipe3: projectData.ManifoldPipe3,
        length1: projectData.ManifoldPipe1Length,
        length2: projectData.ManifoldPipe2Length,
        length3: projectData.ManifoldPipe3Length,
        pipe1Dia: Math_round(
          ConvertUnit(projectData.ManifoldPipe1Dia, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null),
          3
        ),
        pipe2Dia: Math_round(
          ConvertUnit(projectData.ManifoldPipe2Dia, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null),
          3
        ),
        pipe3Dia: Math_round(
          ConvertUnit(projectData.ManifoldPipe3Dia, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null),
          3
        ),
        valvePressure: projectData.ValvePressure ?? 0,
        totalHeadloss: projectData.ManifoldHeadloss ?? 0,
        calcLengths: true,
        showReport: false,
      });
      setValue('firstLateral', currentProjectState.firstLateral);
      setValue('lastLateral', currentProjectState.lastLateral);
    }
  }, [projectDataLoaded]);

  const onNumberOfRowsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const values = getValues();
    setValue('numberOfRowsValue', +e.target.value);
    const convertedSpacing = Math_round(
      ConvertUnit(+values.manifoldSpacing, units.LateralSpacing, units.Length, null),
      3
    );
    const length = calculateTotalLength(+e.target.value, convertedSpacing);

    const flow = calculateFlow({
      flow1: +values.flow1,
      flow2: +values.flow2,
      isBothSides: values.bothSides,
      totalRows: +e.target.value,
    });

    setValue('totalSubmainLengthValue', length);
    setValue('flow', flow);
    dispatch(saveCurrentProjectState({ totalFlowRes: flow }));
    dispatch(
      saveProjectData({
        TotalFlow: +flow,
        TotalManifoldLength: +length,
        TotalRows: +e.target.value,
      })
    );
  };

  const onTotalLengthChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const values = getValues();
    setValue('totalSubmainLengthValue', +e.target.value);
    const convertedSpacing = Math_round(
      ConvertUnit(+values.manifoldSpacing, units.LateralSpacing, units.Length, null),
      3
    );
    const numberOfRows = calculateNumOfRows(+e.target.value, convertedSpacing);

    setValue('numberOfRowsValue', numberOfRows);
    dispatch(saveProjectData({ TotalRows: +numberOfRows, TotalManifoldLength: +e.target.value }));
  };

  const onLateralSpacingChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const values = getValues();
    setValue('manifoldSpacing', +e.target.value);
    dispatch(saveProjectData({ ManifoldSpacing: +e.target.value }));

    const spacing = ConvertUnit(+e.target.value, units.LateralSpacing, units.Length, null);

    if (values.calculationType === 'totalSubmainLen') {
      if (spacing > 0) {
        const numberOfRowsValue = Math.floor(+values.totalSubmainLengthValue / spacing) + 1;
        setValue('numberOfRowsValue', numberOfRowsValue.toString());
        dispatch(saveProjectData({ TotalRows: numberOfRowsValue }));
      } else {
        setValue('numberOfRowsValue', '');
        dispatch(saveProjectData({ TotalRows: 0 }));
      }
    } else {
      if (values.numberOfRowsValue > 0) {
        const totalLengthValue = (+values.numberOfRowsValue - 1) * spacing;
        setValue('totalSubmainLengthValue', totalLengthValue.toString());

        dispatch(saveProjectData({ TotalManifoldLength: totalLengthValue }));
      }
    }
  };

  useEffect(() => {
    if (classType) {
      const values = getValues();

      if (values.maxVelocity && values.flow) {
        const currentPipes = FilterManifoldCatalogs({
          maxVelocity: +values.maxVelocity,
          manifoldClass: classType,
          totalFlow: +values.flow,
          cbManifoldPipe: manifoldsFiltered,
          units,
        });

        if (!currentPipes) return;

        setValue('pipe1', currentPipes.manifoldPipe1Selected);
        setValue('pipe2', currentPipes.manifoldPipe2Selected);
        setValue('pipe3', currentPipes.manifoldPipe3Selected);

        const manifold1Dia = manifolds.find((item) => item.CATLOG === currentPipes.manifoldPipe1Selected)?.INTRNL;
        const manifold2Dia = manifolds.find((item) => item.CATLOG === currentPipes.manifoldPipe2Selected)?.INTRNL;
        const manifold3Dia = manifolds.find((item) => item.CATLOG === currentPipes.manifoldPipe3Selected)?.INTRNL;

        dispatch(
          saveProjectData({
            ManifoldPipe1: currentPipes.manifoldPipe1Selected,
            ManifoldPipe2: currentPipes.manifoldPipe2Selected,
            ManifoldPipe3: currentPipes.manifoldPipe3Selected,
            ManifoldPipe1Dia: manifold1Dia,
            ManifoldPipe2Dia: manifold2Dia,
            ManifoldPipe3Dia: manifold3Dia,
          })
        );
      } else {
        const firstManifold = manifoldsFiltered[0].CATLOG;
        const firstManifoldDia = manifoldsFiltered[0].INTRNL;
        setValue('pipe1', firstManifold);
        setValue('pipe2', firstManifold);
        setValue('pipe3', firstManifold);

        dispatch(
          saveProjectData({
            ManifoldPipe1: firstManifold,
            ManifoldPipe2: firstManifold,
            ManifoldPipe3: firstManifold,
            ManifoldPipe1Dia: firstManifoldDia,
            ManifoldPipe2Dia: firstManifoldDia,
            ManifoldPipe3Dia: firstManifoldDia,
          })
        );
      }
    }
  }, [classType, flow]);

  useEffect(() => {
    if (Object.keys(savedInputs).length) {
      setValue('valvePressure', savedInputs.valve);
      setValue('totalHeadloss', savedInputs.manifoldTotalHeadloss);
      setValue('firstLateral', savedInputs.firstLateral);
      setValue('lastLateral', savedInputs.lastLateral);
    }
  }, []);

  useEffect(() => {
    const formValues = getValues();

    const flow = calculateFlow({
      flow1: +formValues.flow1,
      flow2: +formValues.flow2,
      isBothSides: formValues.bothSides,
      totalRows: +formValues.numberOfRowsValue,
    });

    setValue('flow', flow);
    dispatch(saveCurrentProjectState({ totalFlowRes: flow }));
    dispatch(saveProjectData({ TotalFlow: flow }));
  }, [flow1, flow2, isBothSides, numberOfRows, totalSubmainLength]);

  useEffect(() => {
    const formValues = getValues();

    const result = updateManifoldVelocities(
      {
        ManifoldFlow: +formValues.flow,
        flow1: +formValues.flow1,
        flow2: +formValues.flow2,
        InternalDiameter1: +pipe1Dia,
        InternalDiameter2: +pipe2Dia,
        InternalDiameter3: +pipe3Dia,
        manifoldSpacing: +formValues.manifoldSpacing,
        Pipe1Length: +formValues.length1,
        Pipe2Length: +formValues.length2,
        Pipe3Length: +formValues.length3,
        maxVelocity: +formValues.maxVelocity,
      },
      formValues.bothSides,
      units
    );

    result.velocity1 && setValue('velocity1', result.velocity1.value);
    result.velocity2 && setValue('velocity2', result.velocity2.value);
    result.velocity3 && setValue('velocity3', result.velocity3.value);

    dispatch(
      saveCurrentProjectState({
        manifoldVelocity1: result.velocity1?.value,
        manifoldVelocity2: result.velocity2?.value,
        manifoldVelocity3: result.velocity3?.value,
      })
    );

    if (result.velocity1 && result.velocity1.value > +formValues.maxVelocity) {
      setError('velocity1', {});
    } else {
      clearErrors('velocity1');
    }
    if (result.velocity2 && result.velocity2.value > +formValues.maxVelocity) {
      setError('velocity2', {});
    } else {
      clearErrors('velocity2');
    }
    if (result.velocity3 && result.velocity3.value > +formValues.maxVelocity) {
      setError('velocity3', {});
    } else {
      clearErrors('velocity3');
    }
  }, [pipe1Dia, pipe2Dia, pipe3Dia, isBothSides]);

  useEffect(() => {
    if (pipe1 && currentPipe1) {
      setValue(
        'pipe1Dia',
        Math_round(ConvertUnit(currentPipe1.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null), 3)
      );
    }
  }, [pipe1]);

  useEffect(() => {
    if (pipe2 && currentPipe2) {
      setValue(
        'pipe2Dia',
        Math_round(ConvertUnit(currentPipe2.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null), 3)
      );
    }
  }, [pipe2]);

  useEffect(() => {
    if (pipe3 && currentPipe3) {
      setValue(
        'pipe3Dia',
        Math_round(ConvertUnit(currentPipe3.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.PipeDiameter, null), 3)
      );
    }
  }, [pipe3]);

  const isManifoldChanged = manifoldsFiltered.some(
    (item) => item.CATLOG === pipe1 || item.CATLOG === pipe2 || item.CATLOG === pipe3
  );

  const getCurrentManifoldsData = () => {
    const values = getValues();

    return {
      pipe1Desc: currentPipe1?.CATLOGDESC,
      pipe2Desc: currentPipe2?.CATLOGDESC,
      pipe3Desc: currentPipe3?.CATLOGDESC,
      pipe1Length: values.length1,
      pipe2Length: values.length2,
      pipe3Length: values.length3,
      pipe1Dia: values.pipe1Dia,
      pipe2Dia: values.pipe2Dia,
      pipe3Dia: values.pipe3Dia,
      firstLateralDistance: values.disTo1stLateral,
      username,
      tradingName: projectData.TradingName,
      growerName: projectData.GrowerName,
    };
  };

  const onInputBlur =
    (fieldName: keyof ProjectDetails) => (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      const projectValue =
        typeof projectData[fieldName] === 'number' || projectData[fieldName] === null
          ? +e.target.value
          : e.target.value;
      dispatch(saveProjectData({ [fieldName]: projectValue }));
    };

  const onMaxVelocityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const values = getValues();
    setValue('maxVelocity', +e.target.value);

    if (!classType) return;
    const currentPipes = FilterManifoldCatalogs({
      maxVelocity: +e.target.value,
      manifoldClass: classType,
      totalFlow: values.flow,
      cbManifoldPipe: manifoldsFiltered,
      units,
    });

    if (!currentPipes) return;

    setValue('pipe1', currentPipes.manifoldPipe1Selected);
    setValue('pipe2', currentPipes.manifoldPipe2Selected);
    setValue('pipe3', currentPipes.manifoldPipe3Selected);
  };

  return (
    <Box>
      <ManifoldSlopes open={openSlopes} handleClose={toggleSlopes} />
      <ManifoldReport
        open={openManifoldReport}
        handleClose={toggleManifoldReport}
        currentManifoldsData={getCurrentManifoldsData()}
      />
      {isProjectExist && (
        <Fade in timeout={700} mountOnEnter unmountOnExit>
          <Grid
            container
            component="form"
            noValidate
            rowGap={1.5}
            pt={2}
            onSubmit={handleSubmit(onSubmit)}
            alignItems="flex-start"
          >
            <Grid container rowGap={1} columnSpacing={1} alignItems="center">
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <FormControl fullWidth>
                  <CustomSelectLabel>{t('hlCalc')}</CustomSelectLabel>
                  <Controller
                    name="headlossCalculation"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect label={t('hlCalc')} {...field} onBlur={onInputBlur('ManifoldHeadlossCalc')}>
                        {MANIFOLD_HEADLOSS_CALCULATION.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  disabled={headlossCalculation === 'D.W'}
                  {...register('hwCoef')}
                  label={`${t('hwCoef')}`}
                  type="number"
                  onBlur={onInputBlur('ManifoldHWCoef')}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  {...register('allowableHL')}
                  label={`${t('allowableHL')} (${units.Pressure})`}
                  type="number"
                  onBlur={onInputBlur('ManifoldAllowableHeadloss')}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  {...register('maxVelocity')}
                  label={`${t('maxVelocity')} (${units.Velocity})`}
                  type="number"
                  onBlur={onInputBlur('ManifoldMaximumVelocity')}
                  onChange={onMaxVelocityChange}
                />
              </Grid>

              <Grid item sm="auto" xs={12}>
                <SecondaryButton onClick={toggleSlopes}>{t('defineSlopes')}</SecondaryButton>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <SecondaryButton onClick={toggleUserTemplate}>{t('userTemplate')}</SecondaryButton>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <SecondaryButton onClick={resetForm}>{t('reset')}</SecondaryButton>
              </Grid>
            </Grid>

            <Grid container rowGap={1} columnSpacing={1} alignItems="center">
              <Grid item md="auto" xs={12}>
                <CustomFormControlLabel
                  control={
                    <Controller
                      name="bothSides"
                      control={control}
                      render={({ field }) => <CustomCheckbox checked={!!field.value} {...field} />}
                    />
                  }
                  label={`${t('bothSides')}`}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  {...register('flow1')}
                  label={`${t('flow1')} (${units.TotalFlow})`}
                  type="number"
                  onBlur={onInputBlur('Flow1')}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  {...register('flow2')}
                  disabled={!isBothSides}
                  label={`${t('flow2')} (${units.TotalFlow})`}
                  type="number"
                  onBlur={onInputBlur('Flow2')}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  {...register('lateralInletPr')}
                  label={`${t('lateralInletPr')} (${units.Pressure})`}
                  type="number"
                  onBlur={onInputBlur('LateralInletPressure')}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <CustomInput
                  {...register('flow')}
                  disabled
                  resultBox
                  label={`${t('flow')} (${units.TotalFlow})`}
                  type="number"
                  onBlur={onInputBlur('TotalFlow')}
                />
              </Grid>
            </Grid>
            <Grid container rowGap={1} columnSpacing={1} alignItems="center">
              <Grid item lg={1.5} md={2} sm={3} xs={12}>
                <CustomInput
                  {...register('disTo1stLateral')}
                  label={`${t('disTo1stLateral')} (${units.Length})`}
                  type="number"
                  onBlur={onInputBlur('FirstLateralDistance')}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={3} xs={12}>
                <CustomInput
                  {...register('manifoldSpacing')}
                  label={`${t('lateralSpacing')} (${units.LateralSpacing})`}
                  type="number"
                  onChange={onLateralSpacingChange}
                  onBlur={onInputBlur('ManifoldSpacing')}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="calculationType"
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <Grid container alignItems="center" columnSpacing={1}>
                          <Grid container item xs="auto" alignItems="center">
                            <Grid item>
                              <CustomFormControlLabel
                                value="totalSubmainLen"
                                control={<CustomRadio />}
                                label={`${t('totalSubmainLen')} (${units.Length})`}
                              />
                            </Grid>
                            <Grid item>
                              <CustomInput
                                {...register('totalSubmainLengthValue')}
                                type="number"
                                disabled={calculationType !== 'totalSubmainLen'}
                                resultBox={calculationType !== 'totalSubmainLen'}
                                inputProps={{ sx: { p: 0.5, width: 75 } }}
                                onBlur={onInputBlur('TotalManifoldLength')}
                                onChange={onTotalLengthChange}
                              />
                            </Grid>
                          </Grid>

                          <Grid container item xs="auto" alignItems="center">
                            <Grid item>
                              <CustomFormControlLabel
                                value="numberOfRows"
                                control={<CustomRadio />}
                                label={t('noOfRows')}
                              />
                            </Grid>
                            <Grid item>
                              <CustomInput
                                {...register('numberOfRowsValue')}
                                type="number"
                                inputProps={{ sx: { p: 0.5, width: 75 } }}
                                disabled={calculationType !== 'numberOfRows'}
                                resultBox={calculationType !== 'numberOfRows'}
                                onBlur={onInputBlur('TotalRows')}
                                onChange={onNumberOfRowsChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} rowGap={1}>
              <Grid item lg={1.5} md={2} sm={6} xs={12}>
                <FormControl fullWidth>
                  <CustomSelectLabel>{t('_class')}</CustomSelectLabel>
                  <Controller
                    name="classType"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect label={t('_class')} {...field} onBlur={onInputBlur('SubmainClass')}>
                        {classTypes
                          .sort((a, b) => Number(a) - Number(b))
                          .map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </CustomSelect>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item container lg={10.5} rowGap={1}>
                <Grid container columnSpacing={1} rowGap={1}>
                  <Grid item md={3.6} xs={11}>
                    <FormControl fullWidth>
                      <CustomSelectLabel>{t('pipe1FromValve')}</CustomSelectLabel>
                      {isManifoldChanged && (
                        <Controller
                          name="pipe1"
                          control={control}
                          render={({ field }) => (
                            <CustomSelect label={t('pipe1FromValve')} {...field} onBlur={onInputBlur('ManifoldPipe1')}>
                              {manifoldsFiltered.map((item) => (
                                <MenuItem key={item.CATLOG} value={item.CATLOG}>
                                  {projectData.Region === 'USA'
                                    ? item.AltCATLOGDESC ?? item.CATLOGDESC
                                    : item.CATLOGDESC}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          )}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>{manifoldsFiltered.length}</Typography>
                  </Grid>
                  <Grid item lg={1.5} md={2} sm={4} xs={12}>
                    <CustomInput
                      {...register('velocity1')}
                      InputProps={{
                        readOnly: true,
                      }}
                      resultBox
                      disabled
                      isErrorBox={!!errors.velocity1}
                      type="number"
                      label={`${t('velocity')} (${units.Velocity})`}
                    />
                  </Grid>
                  <Grid item lg={1.5} md={2} sm={4} xs={12}>
                    <CustomInput
                      {...register('length1')}
                      type="number"
                      label={`${t('length')} (${units.Length})`}
                      onBlur={onInputBlur('ManifoldPipe1Length')}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={1} rowGap={1}>
                  <Grid item md={3.6} xs={11}>
                    <FormControl fullWidth>
                      <CustomSelectLabel>{t('pipe2From1')}</CustomSelectLabel>
                      {isManifoldChanged && (
                        <Controller
                          name="pipe2"
                          control={control}
                          render={({ field }) => (
                            <CustomSelect label={t('pipe2From1')} {...field} onBlur={onInputBlur('ManifoldPipe2')}>
                              {manifoldsFiltered.map((item) => (
                                <MenuItem key={item.CATLOG} value={item.CATLOG}>
                                  {projectData.Region === 'USA'
                                    ? item.AltCATLOGDESC ?? item.CATLOGDESC
                                    : item.CATLOGDESC}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          )}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>{manifoldsFiltered.length}</Typography>
                  </Grid>
                  <Grid item lg={1.5} md={2} sm={4} xs={12}>
                    <CustomInput
                      {...register('velocity2')}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="number"
                      label={`${t('velocity')} (${units.Velocity})`}
                      resultBox
                      disabled
                      isErrorBox={!!errors.velocity2}
                    />
                  </Grid>
                  <Grid item lg={1.5} md={2} sm={4} xs={12}>
                    <CustomInput
                      {...register('length2')}
                      type="number"
                      label={`${t('length')} (${units.Length})`}
                      onBlur={onInputBlur('ManifoldPipe2Length')}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={1} rowGap={1}>
                  <Grid item md={3.6} xs={11}>
                    <FormControl fullWidth>
                      <CustomSelectLabel>{t('pipe3From2')}</CustomSelectLabel>
                      {isManifoldChanged && (
                        <Controller
                          name="pipe3"
                          control={control}
                          render={({ field }) => (
                            <CustomSelect label={t('pipe3From2')} {...field} onBlur={onInputBlur('ManifoldPipe3')}>
                              {manifoldsFiltered.map((item) => (
                                <MenuItem key={item.CATLOG} value={item.CATLOG}>
                                  {projectData.Region === 'USA'
                                    ? item.AltCATLOGDESC ?? item.CATLOGDESC
                                    : item.CATLOGDESC}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          )}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>{manifoldsFiltered.length}</Typography>
                  </Grid>
                  <Grid item lg={1.5} md={2} sm={4} xs={12}>
                    <CustomInput
                      {...register('velocity3')}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="number"
                      label={`${t('velocity')} (${units.Velocity})`}
                      resultBox
                      disabled
                      isErrorBox={!!errors.velocity3}
                    />
                  </Grid>
                  <Grid item lg={1.5} md={2} sm={4} xs={12}>
                    <CustomInput
                      {...register('length3')}
                      type="number"
                      label={`${t('length')} (${units.Length})`}
                      onBlur={onInputBlur('ManifoldPipe3Length')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} rowGap={1}>
              <Grid item lg={2} sm={4} xs={12}>
                <CustomInput
                  {...register('pipe1Dia')}
                  type="number"
                  label={`${t('dia1')} (${units.PipeDiameter})`}
                  onBlur={onInputBlur('ManifoldPipe1Dia')}
                />
              </Grid>
              <Grid item lg={2} sm={4} xs={12}>
                <CustomInput
                  {...register('pipe2Dia')}
                  type="number"
                  label={`${t('dia2')} (${units.PipeDiameter})`}
                  onBlur={onInputBlur('ManifoldPipe2Dia')}
                />
              </Grid>
              <Grid item lg={2} sm={4} xs={12}>
                <CustomInput
                  {...register('pipe3Dia')}
                  type="number"
                  label={`${t('dia3')} (${units.PipeDiameter})`}
                  onBlur={onInputBlur('ManifoldPipe3Dia')}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1} rowGap={1} alignItems="center">
              <Grid item lg={2} sm={3} xs={6}>
                <CustomInput
                  {...register('valvePressure')}
                  type="number"
                  resultBox
                  disabled
                  label={`${t('valvePressure')} (${units.Pressure})`}
                  onBlur={onInputBlur('ValvePressure')}
                />
              </Grid>
              <Grid item lg={2} sm={3} xs={6}>
                <CustomInput
                  {...register('firstLateral')}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  resultBox
                  disabled
                  label={`${t('firstLateral')} (${units.Pressure})`}
                />
              </Grid>
              <Grid item lg={2} sm={3} xs={6}>
                <CustomInput
                  {...register('lastLateral')}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  resultBox
                  disabled
                  label={`${t('lastLateral')} (${units.Pressure})`}
                />
              </Grid>
              <Grid item lg={2} sm={3} xs={6}>
                <CustomInput
                  {...register('totalHeadloss')}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  resultBox
                  disabled
                  label={`${t('totalHeadloss')} (${units.Pressure})`}
                />
              </Grid>
              <Grid item>
                <Grid item xs={12}>
                  <CustomFormControlLabel
                    control={
                      <Controller
                        name="calcLengths"
                        control={control}
                        render={({ field }) => <CustomCheckbox sx={{ py: 0 }} checked={!!field.value} {...field} />}
                      />
                    }
                    label={`${t('calcLengths')}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormControlLabel
                    control={
                      <Controller
                        name="showReport"
                        control={control}
                        render={({ field }) => <CustomCheckbox sx={{ py: 0 }} checked={!!field.value} {...field} />}
                      />
                    }
                    label={`${t('showReport')}`}
                  />
                </Grid>
              </Grid>

              <Grid item sm="auto" xs={12}>
                <PrimaryButton type="submit">{t('calculate')}</PrimaryButton>
              </Grid>
            </Grid>

            <ModuleImage variant="manifold" />
          </Grid>
        </Fade>
      )}

      <ProjectName open={openProjectName} handleClose={toggleProjectName} />
    </Box>
  );
};
