import { customAxios } from '../../api/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogsData } from '../models/catalogs/CatalogsData';
import { CatalogsResponse } from '../models/catalogs/CatalogsResponse';
import { CatalogsByFilterData } from '../models/catalogs/CatalogsByFilterData';
import { CatalogsByFilterResponse } from '../models/catalogs/CatalogsByFilterResponse';
import { CatalogsByClassData } from '../models/catalogs/CatalogsByClassData';
import { CatalogsByClassResponse } from '../models/catalogs/CatalogsByClassResponse';

export const getEmitters = createAsyncThunk(
  'catalogs/getEmitters',
  async (params: CatalogsData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogs',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getLaterals = createAsyncThunk(
  'catalogs/getLaterals',
  async (params: CatalogsByFilterData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsByFilterResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogPerDiaClassFlowSpacing',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getManifolds = createAsyncThunk(
  'catalogs/getManifolds',
  async (params: CatalogsByClassData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsByClassResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
        {
          params,
        },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getMainlines = createAsyncThunk(
  'catalogs/getMainlines',
  async (params: CatalogsByClassData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsByClassResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
        {
          params,
        },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
