import { combineReducers, configureStore, isRejected, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authReducer } from './reducers/AuthSlice';
import { projectsReducer } from './reducers/ProjectsSlice';
import { unitsReducer } from './reducers/UnitsSlice';
import { projectDataReducer } from './reducers/ProjectDataSlice';
import { manifoldsReducer } from './reducers/ManifoldsSlice';
import { mainlinesReducer } from './reducers/MainlinesSlice';
import { lateralsReducer } from './reducers/LateralsSlice';
import { masterGroupsReducer } from './reducers/MasterGroupsSlice';
import { groupsReducer } from './reducers/GroupsSlice';
import { emittersReducer } from './reducers/EmittersSlice';
import { userTemplateReducer } from './reducers/UserTemplateSlice';
import { calculationsReducer } from './reducers/CalculationsSlice';
import { emittersEDCReducer } from './reducers/EmittersEDCSlice';
import { EDCCalculationsReducer } from './reducers/EDCCalculationsSlice';

const rootReducer = combineReducers({
  authReducer,
  projectsReducer,
  unitsReducer,
  projectDataReducer,
  emittersReducer,
  lateralsReducer,
  mainlinesReducer,
  manifoldsReducer,
  masterGroupsReducer,
  groupsReducer,
  userTemplateReducer,
  calculationsReducer,
  emittersEDCReducer,
  EDCCalculationsReducer,
});

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejected(action)) {
    toast.error('Network error');
  }

  return next(action);
};

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryErrorLogger),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
