import { deleteProject, getProjects } from './../thunks/ProjectsThunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project, ProjectsResponse } from '../models/projects/ProjectsResponse';

interface ProjectsState {
  projects: Project[];
  currentProject: Project;
  isProjectExist: null | boolean;
  isLoading: boolean;
  isNewProject: boolean;
}

const initialState: ProjectsState = {
  projects: [],
  currentProject: {} as Project,
  isLoading: false,
  isProjectExist: null,
  isNewProject: false,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCurrentProject(state, action: PayloadAction<Pick<Project, 'ProjectID'>>) {
      state.currentProject = state.projects.find(
        (item) => item.ProjectID === action.payload.ProjectID,
      ) as Project;
    },
    setCurrentProjectID(state, action: PayloadAction<number>) {
      state.currentProject = { ...state.currentProject, ProjectID: action.payload };
    },
    setIsNewProject(state, action: PayloadAction<boolean>) {
      state.isNewProject = action.payload;
    },
    setIsProjectExist(state, action: PayloadAction<boolean | null>) {
      state.isProjectExist = action.payload;
    },
    filterDeletedProject(state, action: PayloadAction<number>) {
      state.projects = state.projects.filter((item) => item.ProjectID !== action.payload);
      state.currentProject = state.projects[0];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getProjects.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjects.fulfilled.type, (state, action: PayloadAction<ProjectsResponse>) => {
        state.isLoading = false;
        state.projects = action.payload.GetProjectsResult.RootResults;
        state.currentProject = action.payload.GetProjectsResult.RootResults[0] ?? {};
        state.isProjectExist = action.payload.GetProjectsResult.RootResults[0] ? true : false;
      })
      .addCase(getProjects.rejected.type, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteProject.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled.type, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteProject.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const projectsActions = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
