import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../base';
import { CustomDialog } from '../';

const ReloadModal = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const onClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener("newContentAvailable", () => {
      setShow(true);
    });
  }, [])
  

  return (
    <CustomDialog
      withDialogActions={false}
      maxWidth="xs"
      open={show}
      handleClose={() => {}}
      title={t('New version available')}
    >
      <PrimaryButton onClick={onClick}>{t('reload')}</PrimaryButton>
    </CustomDialog>);
}

export default ReloadModal