import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from '../../api';
import { CatalogsByClassData } from '../models/catalogs/CatalogsByClassData';
import { CatalogsByClassResponse } from '../models/catalogs/CatalogsByClassResponse';
import { CatalogsByFilterData } from '../models/catalogs/CatalogsByFilterData';
import { CatalogsByFilterResponse } from '../models/catalogs/CatalogsByFilterResponse';
import { CatalogsData } from '../models/catalogs/CatalogsData';
import { CatalogsResponse } from '../models/catalogs/CatalogsResponse';

export const getUserTemplateEmitters = createAsyncThunk(
  'userTemplate/getEmitters',
  async (params: CatalogsData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogs',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getUserTemplateLaterals = createAsyncThunk(
  'catalogs/getUserTemplateLaterals',
  async (params: CatalogsByFilterData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsByFilterResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogPerDiaClassFlowSpacing',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getUserTemplateManifolds = createAsyncThunk(
  'catalogs/getUserTemplateManifolds',
  async (params: CatalogsByClassData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsByClassResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
        {
          params,
        },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getUserTemplateMainlines = createAsyncThunk(
  'catalogs/getUserTemplateMainlines',
  async (params: CatalogsByClassData, thunkAPI) => {
    try {
      const response = await customAxios.get<CatalogsByClassResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
        {
          params,
        },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
