import { ProjectDataResponse } from '../models/projectData/ProjectDataResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectDetails } from '../models/projectData/ProjectDataResponse';
import { getProjectData } from '../thunks/ProjectDataThunks';
import { projectsActions } from './ProjectsSlice';
import { authActions } from './AuthSlice';

interface CurrentProjectState extends ProjectDetails {
  maxVelocity: number;
  totalHeadloss: number;
  totalFlowLateral: number;
  totalFlowRes: number;
  isManifoldBothSides: boolean;
  DU: number;
  EU: number;
  PMin: number;
  PMax: number;
  maxLength: number;
  maxLateralLength: number;
  firstLateral: number;
  lastLateral: number;
  avgIrrigationRate: number;
  numberOfOperations: number;
  maxTotalIrrigationTime: number;
  avgDischarge: number;
  waterSourceUsage: number;
  timeOfIrrigation: number;
  mainlineVelocity1: number;
  mainlineVelocity2: number;
  mainlineVelocity3: number;
  manifoldVelocity1: number;
  manifoldVelocity2: number;
  manifoldVelocity3: number;
  lateralCalculation: 'lateral' | 'maxLength';
}
interface projectDataState {
  projectData: ProjectDetails;
  currentProjectState: CurrentProjectState;
  isLoading: boolean;
  projectDataLoaded: boolean;
}

const initialState: projectDataState = {
  projectData: { IsBothSides: false } as ProjectDetails,
  currentProjectState: { lateralCalculation: 'lateral' } as CurrentProjectState,
  isLoading: false,
  projectDataLoaded: false,
};

type ClientData = Pick<
  ProjectDetails,
  'Address' | 'C_Date' | 'Email' | 'Fax' | 'Mobile' | 'Phone' | 'Company' | 'Designer' | 'Dealer' | 'Notes'
>;

export const projectDataSlice = createSlice({
  name: 'projectData',
  initialState,
  reducers: {
    setClientData(state, action: PayloadAction<ClientData>) {
      state.projectData = { ...state.projectData, ...action.payload };
    },
    setLateralSlopes(state, action: PayloadAction<string>) {
      state.projectData.LateralSlopes = action.payload;
    },
    setManifoldSlopes(state, action: PayloadAction<string>) {
      state.projectData.ManifoldSlopes = action.payload;
    },
    saveUserTemplateToProject(state, action: PayloadAction<Partial<ProjectDetails>>) {
      state.projectData = { ...state.projectData, ...action.payload };
    },
    setProjectDataLoaded(state, action: PayloadAction<boolean>) {
      state.projectDataLoaded = action.payload;
    },
    saveProjectData(state, action: PayloadAction<Partial<ProjectDetails>>) {
      state.projectData = { ...state.projectData, ...action.payload };
    },
    saveCurrentProjectState(state, action: PayloadAction<Partial<CurrentProjectState>>) {
      state.currentProjectState = { ...state.currentProjectState, ...action.payload };
    },
    resetCurrentProjectState(state) {
      state.currentProjectState = {
        lateralCalculation: state.currentProjectState.lateralCalculation,
      } as CurrentProjectState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.logout.type, (state) => {
        state.projectDataLoaded = false;
      })
      .addCase(projectsActions.setIsProjectExist.type, (state) => {
        state.projectDataLoaded = true;
      })
      .addCase(getProjectData.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjectData.fulfilled.type, (state, action: PayloadAction<ProjectDataResponse>) => {
        state.isLoading = false;
        state.projectData = action.payload.GetProjectDataResult.RootResults[0];
        state.projectDataLoaded = true;
      })
      .addCase(getProjectData.rejected.type, (state) => {
        state.isLoading = false;
      });
  },
});

export const projectDataActions = projectDataSlice.actions;

export const projectDataReducer = projectDataSlice.reducer;
