import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from '../../api';
import { Groups } from '../models/groups/Groups';
import { GroupsResponse } from '../models/groups/GroupsResponse';

export const getEmitterGroups = createAsyncThunk(
  'groupTypes/getEmitterGroupTypes',
  async (params: Groups, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getLateralGroups = createAsyncThunk(
  'groupTypes/getLateralGroupTypes',
  async (params: Groups, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getMainlineGroups = createAsyncThunk(
  'groupTypes/getMainlineGroupTypes',
  async (params: Groups, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const getManifoldGroups = createAsyncThunk(
  'groupTypes/getManifoldGroupTypes',
  async (params: Groups, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        { params },
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
