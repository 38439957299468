import { CssBaseline } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { AppRoutes } from './routes';
import { ThemeComponent } from './theme';
import './i18n/config';

import 'react-toastify/dist/ReactToastify.min.css';
import { ReloadModal } from './components';

function App() {
  useEffect(() => {
    const appHeight = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  return (
    <ThemeComponent>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <AppRoutes />
        <ToastContainer
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          position="top-center"
          theme="colored"
        />
        <ReloadModal />
      </LocalizationProvider>
    </ThemeComponent>
  );
}

export default App;
