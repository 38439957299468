import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '../components';
import { MainLayout } from '../layouts/MainLayout';
import { LoginPage } from '../pages/Login';
import { NotFoundPage } from '../pages/NotFound';
import { RegisterPage } from '../pages/Register';
import { useAppSelector } from '../store/hooks/redux';

export const AppRoutes = () => {
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  return (
    <Routes>
      <Route index element={<LoginPage />} />
      {/* <Route path="/register" element={<RegisterPage />} />

      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/main" element={<MainLayout />} />
      </Route> */}

      <Route path="/*" element={<LoginPage />} />
    </Routes>
  );
};
