import { ThemeProvider } from '@emotion/react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    sm_map: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const ThemeComponent: React.FC = ({ children }) => {
  let theme = createTheme({
    palette: {
      primary: {
        main: '#428d4d',
        light: '#cce5cf',
      },
      secondary: {
        main: '#fff',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        sm_map: 712,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
