import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from '../../api';
import { MasterGroupsResponse } from '../models/masterGroups/MasterGroupsResponse';

export const getMasterGroups = createAsyncThunk(
  'masterGroups/getMasterGroups',
  async (_, thunkAPI) => {
    try {
      const response = await customAxios.get<MasterGroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetMASTERGROUPS',
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);
