import { SavedInputs } from './../models/calculations/SavedInputs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IrrigationCalcResult } from '../models/calculations/IrrigationCalcResult';
import { LateralCalcResult } from '../models/calculations/LateralCalcResult';
import { LateralLengthCalcResult } from '../models/calculations/LateralLengthCalcResult';
import { MainlineCalcResult } from '../models/calculations/MainlineCalcResult';
import { ManifoldCalcResult } from '../models/calculations/ManifoldCalcResult';

type InputErrors = {
  pMax: boolean;
  pMin: boolean;
}
interface CalculationsState {
  lateralCalcResult: LateralCalcResult;
  lateralLengthCalcResult: LateralLengthCalcResult;
  manifoldCalcResult: ManifoldCalcResult;
  mainlineCalcResult: MainlineCalcResult;
  irrigationCalcResult: IrrigationCalcResult;
  savedInputs: SavedInputs;
  inputErrors: InputErrors;
}

const initialState: CalculationsState = {
  lateralCalcResult: {} as LateralCalcResult,
  lateralLengthCalcResult: {} as LateralLengthCalcResult,
  manifoldCalcResult: {} as ManifoldCalcResult,
  mainlineCalcResult: {} as MainlineCalcResult,
  irrigationCalcResult: {} as IrrigationCalcResult,
  savedInputs: {} as SavedInputs,
  inputErrors: {
    pMax: false,
    pMin: false,
  }
};

export const calculationsSlice = createSlice({
  name: 'calculations',
  initialState,
  reducers: {
    setSavedInputs(state, action: PayloadAction<Partial<SavedInputs>>) {
      state.savedInputs = { ...state.savedInputs, ...action.payload };
    },
    resetLateralSavedInputs(state) {
      state.savedInputs.inletPressure = 0;
      state.savedInputs.EU = 0;
      state.savedInputs.maxVelocity = 0;
      state.savedInputs.totalHeadloss = 0;
      state.savedInputs.totalFlow = 0;
      state.savedInputs.DU = 0;
      state.savedInputs.PMin = 0;
      state.savedInputs.PMax = 0;
      state.savedInputs.maxLength = 0;
    },
    resetManifoldSavedInputs(state) {
      state.savedInputs.valve = 0;
      state.savedInputs.firstLateral = 0;
      state.savedInputs.lastLateral = 0;
      state.savedInputs.manifoldTotalHeadloss = 0;
    },
    setLateralCalcResult(state, action: PayloadAction<LateralCalcResult>) {
      state.lateralCalcResult = action.payload;
    },
    setLateralLengthCalcResult(state, action: PayloadAction<LateralLengthCalcResult>) {
      state.lateralLengthCalcResult = action.payload;
    },
    setManifoldCalcResult(state, action: PayloadAction<ManifoldCalcResult>) {
      state.manifoldCalcResult = action.payload;
    },
    setMainlineCalcResult(state, action: PayloadAction<MainlineCalcResult>) {
      state.mainlineCalcResult = action.payload;
    },
    setIrrigationCalcResult(state, action: PayloadAction<IrrigationCalcResult>) {
      state.irrigationCalcResult = action.payload;
    },
    setInputErrors(state, action:PayloadAction<Partial<InputErrors>>) {
      state.inputErrors = { ...state.inputErrors, ...action.payload };
    }
  },
});

export const calculationsActions = calculationsSlice.actions;

export const calculationsReducer = calculationsSlice.reducer;
